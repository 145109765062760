import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/unit_groups`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      rows: response.unit_groups,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function get(orgId, unitGroupId) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}`,
    method: "GET"
  });
}
function units(orgId, unitGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function removeUnit(orgId, unitGroupId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/remove_unit`,
    method: "PATCH",
    data: {
      unit_id: unitId,
      unit: unitType
    }
  });
}
function unlinked(orgId, unitGroupId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function addUnit(orgId, unitGroupId, unitId, unitType) {
  return request({
    url: `/api/${orgId}/unit_groups/${unitGroupId}/add_unit`,
    method: "POST",
    data: {
      unit_id: unitId,
      unit: unitType
    }
  });
}
function update(organizationId, unitGroupId, rateGroupRangeId, data) {
  return request({
    url: `/api/${organizationId}/unit_groups/${unitGroupId}`,
    method: "PATCH",
    data: {
      ...data,
      rate_group_range_id: rateGroupRangeId
    }
  });
}
function removeFromRange(organizationId, unitGroupId, rateGroupRangeId) {
  return request({
    url: `/api/${organizationId}/unit_groups/${unitGroupId}/remove_from_range`,
    method: "PATCH",
    data: {
      unit_group_id: unitGroupId,
      rate_group_range_id: rateGroupRangeId
    }
  });
}
const UnitGroupService = {
  list,
  get,
  units,
  removeUnit,
  unlinked,
  addUnit,
  update,
  removeFromRange
};
export default UnitGroupService;
