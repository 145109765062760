import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  toDoListImages: []
};
const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setVehicle(state, action) {
      return { ...state, ...action.payload };
    }
  }
});
export const { setVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
