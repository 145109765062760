import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  leads: {
    openLeadModal: false
  },
  ui: {
    openAddUnitsModal: false
  }
};
const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    updatePeopleLeads: (state, action) => {
      return { ...state, leads: { ...state.leads, ...action.payload } };
    },
    resetPeopleLeads: (state) => {
      return { ...state, leads: initialState.leads };
    },
    updatePeopleUi: (state, action) => {
      return { ...state, ui: { ...state.ui, ...action.payload } };
    },
    resetPeopleUi: (state) => {
      return { ...state, ui: initialState.ui };
    }
  }
});
export const {
  updatePeopleLeads,
  resetPeopleLeads,
  updatePeopleUi,
  resetPeopleUi
} = peopleSlice.actions;
export default peopleSlice.reducer;
export const selectPeopleLeadsState = createSelector((state) => state.people, (people) => people.leads);
