import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  organization: null,
  name: "",
  description: "",
  domainName: "",
  language: "",
  currency: "",
  tax_rate: 0,
  theme: "default",
  adrStreet: "",
  adrUnit: "",
  adrCity: "",
  adrState: "",
  adrCountry: "",
  adrPostalCode: ""
};
const createWebsiteSlice = createSlice({
  name: "newWebsite",
  initialState,
  reducers: {
    updateNewWebsite: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNewWebsite: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const { updateNewWebsite, resetNewWebsite } = createWebsiteSlice.actions;
export default createWebsiteSlice.reducer;
export const selectNewWebsiteState = createSelector((state) => state.newWebsite, (website) => website);
