import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  printDeliveryReport: false
};
const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateReports: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetReports: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const { updateReports, resetReports } = reportsSlice.actions;
export default reportsSlice.reducer;
export const selectReportsState = createSelector((state) => state.reports, (reports) => reports);
