import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserDetails,
  updateUserDetails
} from "../../redux/slices/userDetails";
const InitialReduxState = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  useEffect(() => {
    var _a, _b, _c, _d, _e, _f, _g;
    dispatch(updateUserDetails({
      ...userDetails,
      admin: props.user.admin,
      id: props.user.id,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      permissions: props.user.permissions,
      role: props.user.role,
      role_name: props.user.role_name,
      type: props.user.type,
      organization: props.organization,
      isRVshareUser: props.organization.organization_type === "rv_fleet",
      rvshare: {
        ...userDetails.rvshare,
        external_id: (_b = (_a = props.rvshare_data) == null ? void 0 : _a.channel_data) == null ? void 0 : _b.user_id,
        mappable_id: (_c = props.rvshare_data) == null ? void 0 : _c.mappable_id,
        id: (_d = props.rvshare_data) == null ? void 0 : _d.id,
        hasAttemptedSync: (_f = (_e = props.rvshare_data) == null ? void 0 : _e.channel_data) == null ? void 0 : _f.hasAttemptedSync,
        active: ((_g = props.rvshare_data) == null ? void 0 : _g.status) === "active",
        mapping_data: props.rvshare_data
      }
    }));
  }, []);
  return null;
};
export default InitialReduxState;
