import { createSelector, createSlice } from "@reduxjs/toolkit";
import { rvshareUI } from "./rvshareInitialState";
const initialState = {
  ui: {
    isDirty: false,
    isEditing: false,
    isSaving: false,
    isLoaded: false,
    loading: true,
    openInflatorCheckModal: false
  },
  rvshare: {
    ui: rvshareUI
  },
  airbnb: {
    addAccountStepOne: false,
    allowAddAccount: false,
    openMappingModal: false,
    openUnlistModal: false,
    openDistributionModal: false,
    openManualAirbnbModal: false,
    unit: null,
    mapping: null
  }
};
const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    updateChannelsUI: (state, action) => {
      return { ...state, ui: { ...state.ui, ...action.payload } };
    },
    resetChannelsUI: (state) => {
      return { ...state, ui: initialState };
    },
    updateChannelsAirbnb: (state, action) => {
      return {
        ...state,
        airbnb: { ...state.airbnb, ...action.payload }
      };
    },
    resetChannelsAirbnb: (state) => {
      return { ...state, airbnb: { ...initialState.airbnb } };
    },
    updateChannelsRvShareUI: (state, action) => {
      return {
        ...state,
        rvshare: {
          ui: { ...state.rvshare.ui, ...action.payload }
        }
      };
    },
    resetChannelsRvShareUI: (state) => {
      return { ...state, rvshare: { ui: rvshareUI } };
    }
  }
});
export const {
  updateChannelsUI,
  resetChannelsUI,
  updateChannelsRvShareUI,
  resetChannelsRvShareUI,
  updateChannelsAirbnb,
  resetChannelsAirbnb
} = channelsSlice.actions;
export default channelsSlice.reducer;
export const selectChannelsUI = createSelector((state) => state.channels.ui, (channelsUI) => channelsUI);
export const selectChannelsRvShareUI = createSelector((state) => state.channels.rvshare.ui, (rvShareUI) => rvShareUI);
export const selectChannelsAirbnb = createSelector((state) => state.channels.airbnb, (rvShareUI) => rvShareUI);
