import request from "apiClient";
export default class ReportService {
  static index(organizationId, reportType) {
    return request({
      url: `/api/${organizationId}/reports`,
      method: "GET",
      params: { report_type: reportType }
    });
  }
  static new(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/reports/new`,
      method: "GET",
      params
    });
  }
  static create(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/reports/create`,
      method: "POST",
      data: params
    });
  }
  static possibleReports(organizationId, userId, params = {}) {
    return request({
      url: `/api/${organizationId}/possible_reports`,
      method: "GET",
      params: { user_id: userId, ...params }
    });
  }
  static favorite(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/favorite`,
      method: "PUT",
      params
    });
  }
}
