import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  telephone: "",
  assigneeId: null,
  status: "no_action",
  tag: null,
  bookingType: "guest",
  unitId: null,
  units: []
};
const createOpportunitySlice = createSlice({
  name: "newOpportunity",
  initialState,
  reducers: {
    updateNewOpportunity: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNewOpportunity: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const {
  updateNewOpportunity,
  resetNewOpportunity
} = createOpportunitySlice.actions;
export default createOpportunitySlice.reducer;
export const selectNewOpportunityState = createSelector((state) => state.newOpportunity, (opportunity) => opportunity);
