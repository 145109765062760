import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  inboxUiState,
  activeConversationState,
  conversationListState,
  inboxNav,
  inboxSidebar,
  inboxParams,
  inboxFilters,
  pendingMessage
} from "./initialStates";
const unifiedInboxSlice = createSlice({
  name: "unifiedInbox",
  initialState: {
    ui: inboxUiState,
    inboxParams,
    inboxFilters,
    inboxConversationList: conversationListState,
    inboxActiveConversation: activeConversationState,
    inboxNav,
    inboxSidebar,
    pendingMessage
  },
  reducers: {
    updateInboxUi: (state, action) => {
      return { ...state, ui: { ...state.ui, ...action.payload } };
    },
    resetInboxUi: (state) => {
      return {
        ...state,
        ui: inboxUiState
      };
    },
    updateInboxFilters: (state, action) => {
      return {
        ...state,
        inboxFilters: { ...state.inboxFilters, ...action.payload }
      };
    },
    updateInboxParams: (state, action) => {
      return {
        ...state,
        inboxParams: { ...state.inboxParams, ...action.payload }
      };
    },
    updateInboxConversationList: (state, action) => {
      return {
        ...state,
        inboxConversationList: {
          ...state.inboxConversationList,
          ...action.payload
        }
      };
    },
    updateInboxActiveConversation: (state, action) => {
      return {
        ...state,
        inboxActiveConversation: {
          ...state.inboxActiveConversation,
          ...action.payload
        }
      };
    },
    resetInboxActiveConversation: (state) => {
      return {
        ...state,
        inboxActiveConversation: activeConversationState
      };
    },
    updateInboxSidebar: (state, action) => {
      return {
        ...state,
        inboxSidebar: { ...state.inboxSidebar, ...action.payload }
      };
    },
    resetInboxSidebar: (state) => {
      return {
        ...state,
        inboxSidebar
      };
    },
    updatePendingMessage: (state, action) => {
      return {
        ...state,
        pendingMessage: { ...state.pendingMessage, ...action.payload }
      };
    },
    resetPendingMessage: (state) => {
      return {
        ...state,
        pendingMessage
      };
    }
  }
});
export const {
  updateInboxUi,
  updateInboxParams,
  updateInboxConversationList,
  updateInboxActiveConversation,
  updateInboxFilters,
  resetInboxActiveConversation,
  resetInboxUi,
  updateInboxSidebar,
  resetInboxSidebar,
  updatePendingMessage,
  resetPendingMessage
} = unifiedInboxSlice.actions;
export default unifiedInboxSlice.reducer;
export const selectInboxUIState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.ui);
export const selectInboxParamsState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxParams);
export const selectInboxNavState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxNav);
export const selectInboxConversationListState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxConversationList);
export const selectInboxActiveConversationState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxActiveConversation);
export const selectInboxFiltersState = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxFilters);
export const selectInboxConversationCount = createSelector((state) => state.unifiedInbox.inboxConversationList, (conversationList) => conversationList.conversations.length);
export const selectInboxActiveConversationId = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.id);
export const selectInboxActiveCustomerId = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => {
  var _a;
  return (_a = activeConversation.customer) == null ? void 0 : _a.id;
});
export const selectInboxActiveThread = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.activeThread);
export const selectInboxOtherThreads = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.otherThreads);
export const selectInboxCustomer = createSelector((state) => state.unifiedInbox.inboxActiveConversation, (activeConversation) => activeConversation.customer);
export const selectInboxSidebar = createSelector((state) => state.unifiedInbox, (inbox) => inbox.inboxSidebar);
export const selectConversationById = (conversationId) => createSelector((state) => state.unifiedInbox.inboxConversationList.conversations, (conversations) => conversations.filter((convo) => convo.id === conversationId)[0]);
export const selectPendingMessage = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage);
export const selectPendingMessageBody = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage.body);
export const selectPendingMessageAttachmentPreviews = createSelector((state) => state.unifiedInbox, (inboxState) => inboxState.pendingMessage.attachmentPreviews);
