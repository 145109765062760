import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  parentId: "",
  companyType: "",
  currency: "",
  description: "",
  language: "",
  name: "",
  organizationType: "",
  subdomain: "",
  adrStreet: "",
  adrUnit: "",
  adrCity: "",
  adrState: "",
  adrCountry: "",
  adrPostalCode: "",
  termsAccepted: false,
  ccAccepted: false,
  tier: "",
  interval: "",
  submitting: false,
  brandName: "",
  brandDescription: "",
  paymentProcessor: ""
};
const createOrganizationSlice = createSlice({
  name: "newOrganization",
  initialState,
  reducers: {
    updateNewOrganization: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNewOrganization: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const {
  updateNewOrganization,
  resetNewOrganization
} = createOrganizationSlice.actions;
export default createOrganizationSlice.reducer;
export const selectNewOrganization = createSelector((state) => state.newOrganization, (organization) => organization);
