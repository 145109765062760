import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  cmsVersion: null,
  detailsLayout: false,
  pageBuilder: {
    pageSlices: [],
    isDirty: false,
    isSaved: false,
    isSaving: false,
    isHomePage: false,
    sliceAction: null,
    jumpToSliceAction: null,
    data: {
      meta_title: null,
      meta_description: null,
      content: null,
      description: null,
      featured: null,
      slug: null,
      payload: null,
      published: null,
      published_at: null,
      template: null,
      title: null,
      contactForm: null,
      css_override: null,
      scripts_override: null
    }
  }
};
const websiteCMSSlice = createSlice({
  name: "websiteCms",
  initialState,
  reducers: {
    updateWebsiteCMS: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetWebsiteCMS: (state) => {
      return { ...state, ...initialState };
    },
    updateWebsiteCMSPageBuilder: (state, action) => {
      return {
        ...state,
        pageBuilder: { ...state.pageBuilder, ...action.payload }
      };
    },
    resetWebsiteCMSPageBuilder: (state, action) => {
      return {
        ...state,
        pageBuilder: initialState.pageBuilder
      };
    },
    updateWebsiteCMSPageSlices: (state, action) => {
      return {
        ...state,
        pageBuilder: {
          ...state.pageBuilder,
          pageSlices: action.payload
        }
      };
    },
    resetWebsiteCMSPageSlices: (state, action) => {
      return {
        ...state,
        pageBuilder: {
          ...state.pageBuilder,
          pageSlices: []
        }
      };
    },
    updateWebsiteCMSPageData: (state, action) => {
      return {
        ...state,
        pageBuilder: {
          ...state.pageBuilder,
          data: { ...state.pageBuilder.data, ...action.payload }
        }
      };
    },
    resetWebsiteCMSPageData: (state, action) => {
      return {
        ...state,
        pageBuilder: {
          ...state.pageBuilder,
          data: { ...initialState.pageBuilder.data }
        }
      };
    }
  }
});
export const {
  updateWebsiteCMS,
  resetWebsiteCMS,
  updateWebsiteCMSPageBuilder,
  resetWebsiteCMSPageBuilder,
  updateWebsiteCMSPageData,
  resetWebsiteCMSPageData,
  updateWebsiteCMSPageSlices,
  resetWebsiteCMSPageSlices
} = websiteCMSSlice.actions;
export default websiteCMSSlice.reducer;
export const selectWebsiteCMSState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms);
export const selectWebsiteCMSPageBuilderState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms.pageBuilder);
export const selectWebsiteCMSPageSlicesState = createSelector((state) => state.websiteCms, (websiteCms) => websiteCms.pageBuilder.pageSlices);
