import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  name: "",
  email: "",
  role: "",
  team_id: null,
  telephone: "",
  adr_street: "",
  adr_unit: "",
  adr_city: "",
  adr_state: "",
  adr_country: "",
  adr_postal_code: "",
  entity: "",
  user_group: "",
  payout_frequency: "monthly",
  portfolio: "",
  margin: 0,
  needs_confirmation: false,
  business_name: "",
  teams: [],
  showOverlay: false,
  calculation_type: "percent"
};
const createEmployeeSlice = createSlice({
  name: "newEmployee",
  initialState,
  reducers: {
    updateNewEmployee: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNewEmployee: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const {
  updateNewEmployee,
  resetNewEmployee
} = createEmployeeSlice.actions;
export default createEmployeeSlice.reducer;
export const selectNewEmployee = createSelector((state) => state.newEmployee, (employee) => employee);
