import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  id: null,
  first_name: "",
  last_name: "",
  permissions: {},
  role: null,
  role_name: null,
  type: null,
  organization: {},
  rvshare: {
    external_id: null,
    mappable_id: null,
    id: null,
    hasAttemptedSync: false,
    active: false,
    syncMonitor: "rest"
  }
};
const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUserDetails: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const { updateUserDetails, resetUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
export const selectUserDetails = createSelector((state) => state.userDetails, (userDetails) => userDetails);
export const selectUserOrganization = createSelector((state) => state.userDetails.organization, (organization) => organization);
