import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ui: {
    cleanUpNewOrder: false
  },
  newWorkOrder: {
    submittable: false,
    unit_id: "",
    assignee_type: "",
    assignee_id: "",
    wo_type: "",
    job_type: "",
    description: "",
    title: "",
    task_types: [],
    requested_by: null,
    booking_id: "",
    isFocused: false,
    date: null,
    time: "",
    amount: ""
  }
};
const workOrdersSlice = createSlice({
  name: "workOrders",
  initialState,
  reducers: {
    updateWorkOrdersUI: (state, action) => {
      return { ...state, ui: { ...state.ui, ...action.payload } };
    },
    resetWorkOrdersUI: (state) => {
      return { ...state, ui: initialState.ui };
    },
    updateNewWorkOrder: (state, action) => {
      return {
        ...state,
        newWorkOrder: { ...state.newWorkOrder, ...action.payload }
      };
    },
    resetNewWorkOrder: (state) => {
      return { ...state, newWorkOrder: initialState.newWorkOrder };
    }
  }
});
export const {
  updateWorkOrdersUI,
  resetWorkOrdersUI,
  updateNewWorkOrder,
  resetNewWorkOrder
} = workOrdersSlice.actions;
export default workOrdersSlice.reducer;
