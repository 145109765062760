import { createSlice, createSelector } from "@reduxjs/toolkit";
const initialState = {
  selectedFilterKeys: [],
  search: "",
  inventory: {
    portfolio_id: "",
    subportfolio_id: "",
    unit_group_id: ""
  },
  channel_id: "",
  reviews_filters: {
    date_filter: "check_in",
    start_date: null,
    end_date: null,
    rating: "",
    status: "pending"
  },
  date_range: {
    start_date: null,
    end_date: null
  },
  date_range_and_type: {
    date_filter: "check_in",
    start_date: null,
    end_date: null
  },
  booking_status: "",
  booking_payment_status: "",
  booking_generation_method: "",
  booking_reservation_type: "",
  tax_account_id: 0,
  sort: {
    tax: ""
  },
  yearly_earnings_year: null
};
const filterWidgetSlice = createSlice({
  name: "filterWidget",
  initialState,
  reducers: {
    updateFilterWidget: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetFilterWidget: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const {
  updateFilterWidget,
  resetFilterWidget
} = filterWidgetSlice.actions;
export default filterWidgetSlice.reducer;
export const selectFilterWidgetState = createSelector((state) => state.filterWidget, (filterWidget) => filterWidget);
export const selectFilterWidgetInventory = createSelector((state) => state.filterWidget, (filterWidget) => filterWidget.inventory);
export const selectAllReviewFilters = createSelector((state) => state.filterWidget, (filters) => ({
  search: filters.search,
  portfolio_id: filters.inventory.portfolio_id,
  subportfolio_id: filters.inventory.subportfolio_id,
  unit_group_id: filters.inventory.unit_group_id,
  date_range: {
    date_filter: filters.reviews_filters.date_filter,
    start_date: filters.reviews_filters.start_date,
    end_date: filters.reviews_filters.end_date
  },
  rating: filters.reviews_filters.rating,
  status: filters.reviews_filters.status
}));
export const selectDefaultReportFilters = createSelector((state) => state.filterWidget, (filters) => ({
  channel_id: filters.channel_id,
  booking_status: filters.booking_status,
  booking_payment_status: filters.booking_payment_status,
  booking_generation_method: filters.booking_generation_method,
  booking_reservation_type: filters.booking_reservation_type,
  search: filters.search,
  portfolio_id: filters.inventory.portfolio_id,
  subportfolio_id: filters.inventory.subportfolio_id,
  unit_group_id: filters.inventory.unit_group_id,
  date_range: {
    date_filter: filters.date_range_and_type.date_filter,
    start_date: filters.date_range_and_type.start_date,
    end_date: filters.date_range_and_type.end_date
  }
}));
export const selectTaxAuditReportFilters = createSelector((state) => state.filterWidget, (filters) => ({
  date_range: {
    start_date: filters.date_range.start_date,
    end_date: filters.date_range.end_date
  },
  tax_account_id: filters.tax_account_id,
  sort: filters.sort.tax
}));
export const selectDateRangeOnlyReportFilters = createSelector((state) => state.filterWidget, (filters) => ({
  date_range: {
    date_filter: filters.date_range_and_type.date_filter,
    start_date: filters.date_range_and_type.start_date,
    end_date: filters.date_range_and_type.end_date
  }
}));
export const selectAllOccupancyReportFilters = createSelector((state) => state.filterWidget, (filters) => ({
  search: filters.search,
  portfolio_id: filters.inventory.portfolio_id,
  subportfolio_id: filters.inventory.subportfolio_id,
  unit_group_id: filters.inventory.unit_group_id,
  date_range: {
    date_filter: filters.date_range_and_type.date_filter,
    start_date: filters.date_range_and_type.start_date,
    end_date: filters.date_range_and_type.end_date
  }
}));
export const selectAllUnitExpensesReportFilters = createSelector((state) => state.filterWidget, (filters) => ({
  search: filters.search,
  portfolio_id: filters.inventory.portfolio_id,
  subportfolio_id: filters.inventory.subportfolio_id,
  unit_group_id: filters.inventory.unit_group_id
}));
