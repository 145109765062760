import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  invoices: {
    refreshInvoices: false
  },
  chartOfAccounts: {
    openLedgerModal: false,
    openJournalModal: false
  },
  earning: {}
};
const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    updateAccountingInvoices: (state, action) => {
      return { ...state, invoices: { ...state.invoices, ...action.payload } };
    },
    resetAccountingInvoices: (state) => {
      return { ...state, invoices: initialState.invoices };
    },
    updateAccountingChartOfAccounts: (state, action) => {
      return {
        ...state,
        chartOfAccounts: { ...state.chartOfAccounts, ...action.payload }
      };
    },
    resetAccountingChartOfAccounts: (state) => {
      return { ...state, chartOfAccounts: initialState.chartOfAccounts };
    },
    loadEarningAcccount: (state, action) => {
      return { ...state, earning: { ...action.payload } };
    },
    resetEarningAccount: (state) => {
      return { ...state, earning: initialState.earning };
    }
  }
});
export const {
  updateAccountingInvoices,
  resetAccountingInvoices,
  updateAccountingChartOfAccounts,
  resetAccountingChartOfAccounts,
  loadEarningAcccount,
  resetEarningAccount
} = accountingSlice.actions;
export default accountingSlice.reducer;
export const selectAccountingInvoicesState = createSelector((state) => state.accounting, (accounting) => accounting.invoices);
export const selectAccountingChartOfAccounts = createSelector((state) => state.accounting, (accounting) => accounting.chartOfAccounts);
export const selectEarningAccount = createSelector((state) => state.accounting, (accounting) => accounting.earning);
