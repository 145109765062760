import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  bulkEditorUi,
  bulkEditorActiveFeature,
  bulkEditorCandidateList
} from "./initialStates";
const bulkEditorSlice = createSlice({
  name: "bulkEditor",
  initialState: {
    ui: bulkEditorUi,
    activeFeature: bulkEditorActiveFeature,
    candidateList: bulkEditorCandidateList
  },
  reducers: {
    updateBulkEditorUi: (state, action) => {
      return {
        ...state,
        ui: { ...state.ui, ...action.payload }
      };
    },
    resetBulkEditorUi: (state) => {
      return {
        ...state,
        ui: bulkEditorUi
      };
    },
    updateBulkEditorActiveFeature: (state, action) => {
      return {
        ...state,
        activeFeature: { ...state.activeFeature, ...action.payload }
      };
    },
    resetBulkEditorActiveFeature: (state) => {
      return {
        ...state,
        activeFeature: bulkEditorActiveFeature
      };
    },
    updateBulkEditorCandidateList: (state, action) => {
      return {
        ...state,
        candidateList: { ...state.candidateList, ...action.payload }
      };
    },
    resetBulkEditorCandidateList: (state) => {
      return {
        ...state,
        candidateList: bulkEditorCandidateList
      };
    }
  }
});
export const {
  updateBulkEditorUi,
  resetBulkEditorUi,
  updateBulkEditorActiveFeature,
  resetBulkEditorActiveFeature,
  updateBulkEditorCandidateList,
  resetBulkEditorCandidateList
} = bulkEditorSlice.actions;
export default bulkEditorSlice.reducer;
export const selectBulkEditorUi = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.ui);
export const selectBulkEditorActiveFeature = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.activeFeature);
export const selectBulkEditorCandidateList = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.candidateList);
export const selectBulkEditorCandidateCount = createSelector((state) => state.bulkEditor, (bulkEditor) => bulkEditor.candidateList.candidates.length);
