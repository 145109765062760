import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import Notification from "sharedComponents/Notification";
import Navigation from "./components/Navigation";
const SignIn = loadable(() => import("./pages/SignIn"));
const SignUp = loadable(() => import("./pages/SignUp"));
const ForgotPassword = loadable(() => import("./pages/ForgotPassword"));
const Confirmation = loadable(() => import("./pages/Confirmation"));
const Registration = loadable(() => import("./pages/Registration"));
const appStyles = {
  display: "flex",
  flexFlow: "column",
  height: "100%"
};
export default class AuthenticationApp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement(Router, {
      basename: "/"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "authenticationApp",
      style: appStyles
    }, /* @__PURE__ */ React.createElement(Notification, null), /* @__PURE__ */ React.createElement(Navigation, {
      ...this.props
    }), /* @__PURE__ */ React.createElement("main", {
      className: "authentication"
    }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
      path: "/sign-in",
      render: (routeProps) => /* @__PURE__ */ React.createElement(SignIn, {
        ...this.props,
        ...routeProps
      })
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/sign-up",
      render: (routeProps) => /* @__PURE__ */ React.createElement(SignUp, {
        ...this.props,
        ...routeProps
      })
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/forgot-password",
      render: (routeProps) => /* @__PURE__ */ React.createElement(ForgotPassword, {
        ...this.props,
        ...routeProps
      })
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/confirmations/new",
      render: (routeProps) => /* @__PURE__ */ React.createElement(Confirmation, {
        ...this.props,
        ...routeProps
      })
    }), /* @__PURE__ */ React.createElement(Route, {
      path: "/registrations/edit",
      render: (routeProps) => /* @__PURE__ */ React.createElement(Registration, {
        ...this.props,
        ...routeProps
      })
    })))));
  }
}
