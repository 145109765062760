import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  images: {
    resyncPropertyPhotos: false,
    uploadPropertyPhotos: false,
    uploadUnitPhotos: false,
    uploadVehiclePhotos: false
  },
  details: {
    latitude: null,
    longitude: null,
    openCloneVehicle: false,
    vehicleIsSavingTag: "",
    initialPropsChecked: false
  },
  pricing: {
    openSecurityDepositModal: false,
    openBookingDepositModal: false
  }
};
const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    updateListingImages: (state, action) => {
      return { ...state, images: { ...state.images, ...action.payload } };
    },
    resetListingImages: (state) => {
      return { ...state, images: initialState.images };
    },
    updateListingDetails: (state, action) => {
      return { ...state, details: { ...state.details, ...action.payload } };
    },
    resetListingDetails: (state) => {
      return { ...state, details: initialState.details };
    },
    updateListingPricing: (state, action) => {
      return { ...state, pricing: { ...state.pricing, ...action.payload } };
    },
    resetListingPricing: (state) => {
      return { ...state, pricing: initialState.pricing };
    }
  }
});
export const {
  updateListingImages,
  resetListingImages,
  updateListingDetails,
  resetListingDetails,
  updateListingPricing,
  resetListingPricing
} = listingsSlice.actions;
export default listingsSlice.reducer;
export const selectListingImagesState = createSelector((state) => state.listings, (listings) => listings.images);
export const selectListingDetailsState = createSelector((state) => state.listings, (listings) => listings.details);
export const selectListingPricingState = createSelector((state) => state.listings, (listings) => listings.pricing);
