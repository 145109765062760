import isNull from "lodash/isNull";
import isNaN from "lodash/isNaN";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import isUndefined from "lodash/isUndefined";
const isNotNull = (value) => !isNull(value);
const isNotUndefined = (value) => !isUndefined(value);
const isNotNullOrUndefined = (value) => isNotNull(value) && isNotUndefined(value);
const isNotNullOrZero = (value) => isNotNull(value) && Number(value) !== 0 && !isNaN(value);
const isNotNullOrEmptyString = (value) => isNotNull(value) && value !== "";
const isNotEmptyObject = (value) => Object.keys(value).length > 0;
const parseIntWithRadix10 = (value) => parseInt(value, 10);
const translateObjectKeysFromCamelCaseToSnakeCase = (inObject) => {
  const outObject = {};
  Object.keys(inObject).forEach((key) => {
    if (snakeCase(key) !== key) {
      outObject[snakeCase(key)] = inObject[key];
    } else {
      outObject[key] = inObject[key];
    }
  });
  return outObject;
};
const translateObjectKeysFromSnakeCaseToCamelCase = (inObject) => {
  const outObject = {};
  Object.keys(inObject).forEach((key) => {
    if (camelCase(key) !== key) {
      outObject[camelCase(key)] = inObject[key];
    } else {
      outObject[key] = inObject[key];
    }
  });
  return outObject;
};
const checkObjectKeyExists = (object, key) => {
  const checkObject = Object.keys(object).filter((obj) => obj === key);
  return checkObject.length > 0;
};
const safelyParseJSON = (json) => {
  let parsed = null;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
  }
  return parsed;
};
const getOptionFromArray = (array, value) => {
  return array.filter((arr) => arr.value === value)[0];
};
const getOptionLabelFromArray = (array, value) => {
  const filteredArray = array.filter((arr) => arr.value === value);
  return filteredArray.length > 0 ? filteredArray[0].label : null;
};
const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0)
    return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0)
    return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};
export {
  isNotNull,
  isNotNullOrZero,
  isNotNullOrEmptyString,
  isNotEmptyObject,
  parseIntWithRadix10,
  translateObjectKeysFromCamelCaseToSnakeCase,
  translateObjectKeysFromSnakeCaseToCamelCase,
  isNotUndefined,
  isNotNullOrUndefined,
  checkObjectKeyExists,
  safelyParseJSON,
  getOptionFromArray,
  getOptionLabelFromArray,
  bytesToSize
};
