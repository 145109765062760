import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  details: {
    sendCheckIn: false,
    sendPaymentRequest: false,
    markGuestCardInvalid: false,
    cancelInactiveCard: false,
    unarchiveBooking: false,
    uncancelBooking: false,
    deleteBooking: false,
    cancelBooking: false,
    isLoading: true,
    clickPrintButton: false
  },
  showCalendarOptions: false
};
const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    updateReservations: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetReservations: (state) => {
      return { ...state, ...initialState };
    },
    updateReservationDetails: (state, action) => {
      return { ...state, details: { ...state.details, ...action.payload } };
    },
    resetReservationDetails: (state) => {
      return { ...state, details: initialState.details };
    }
  }
});
export const {
  updateReservationDetails,
  resetReservationDetails,
  updateReservations,
  resetReservations
} = reservationsSlice.actions;
export default reservationsSlice.reducer;
export const selectReservationsDetailsState = createSelector((state) => state.reservations, (reservations) => reservations.details);
